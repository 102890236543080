import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CoreFilter from "./../CoreFilter";

// const communityTypes = [
//   { id: "Food", name: "Food", color: "var(--primary)" } /* (same) */,
//   {
//     id: "Art & Design",
//     name: "Art & Design",
//     color: "var(--primary)",
//   } /* —> Art*/,
//   {
//     id: "Fitness & Wellness",
//     name: "Fitness & Wellness",
//     color: "var(--primary)",
//   } /* --> Lifestyle & Wellness */,
//   {
//     id: "Artists",
//     name: "Artists",
//     color: "var(--primary)",
//   } /* --> Makers */,
//   {
//     id: "Kids",
//     name: "Kids",
//     color: "var(--primary)",
//   } /*  --> Culture & Education  */,
//   {
//     id: "Theatre & Film",
//     name: "Theatre & Film",
//     color: "var(--primary)",
//   } /* --> Production & Consultancy */,
//   {
//     id: "Performing Arts",
//     name: "Performing Arts",
//     color: "var(--primary)",
//   } /*--> disappears? */,
// ];

// export const getCommunityTypeFromID = (id) => {
//   const list = communityTypes.filter((x) => x.id === id);
//   if (list.length) {
//     return list[0].name;
//   } else {
//     console.error("Problem: ", id);
//   }
// };

const CommunityFilter = ({ selected, selectLocation, options }) => {
  const communityTypes = options?.map((option) => {
    return {
      id: option?.id,
      name: option?.title,
      color: "var(--primary)",
    };
  });

  if (selectLocation) {
    selectLocation = options?.length ? options?.find((option) => selectLocation === decodeURI(option?.title?.toLowerCase()))?.id : "";
  }
  selectLocation = selectLocation ?? "";
  // switch (selectLocation) {
  //   case "food":
  //     selectLocation = "Food";
  //     break;
  //   case "art-and-design":
  //     selectLocation = "Art & Design";
  //     break;
  //   case "fitness-and-wellness":
  //     selectLocation = "Fitness & Wellness";
  //     break;
  //   case "artists":
  //     selectLocation = "Artists";
  //     break;
  //   case "kids":
  //     selectLocation = "Kids";
  //     break;
  //   case "theatre-and-film":
  //     selectLocation = "Theatre & Film";
  //     break;
  //   case "performing-arts":
  //     selectLocation = "Performing Arts";
  //     break;
  //   default:
  //     selectLocation = "";
  // }

  return <CoreFilter selected={selected} filterList={communityTypes} useColor modal selectLocation={selectLocation} />;
};

export default CommunityFilter;

CommunityFilter.propTypes = {
  selected: PropTypes.func,
};
