import React from "react";

const MiscSocialSmallParticipant = ({ color, extraClass, participantLinks }) => {
  if (color === -1) {
    color = "var(--black)";
  } else if (color === 1) {
    color = "var(--blue)";
  } else if (color === 2) {
    color = "var(--pink)";
  } else if (color === 3) {
    color = "var(--green2)";
  }

  return (
    <aside className={`socialicons ${extraClass ? extraClass : ""}`}>
      {!participantLinks?.website ? null : (
        <a style={{ marginRight: "2px" }} id="website" href={participantLinks?.website} target="_blank" rel="noopener noreferrer">
          <svg width={32} height={32} fill="none" viewBox="0 0 32 32">
            <path
              fill={color || "#fff"}
              d="m16 6.25c-5.38 0-9.75 4.37-9.75 9.75s4.37 9.75 9.75 9.75 9.75-4.37 9.75-9.75-4.37-9.75-9.75-9.75zm0 18c-1.1 0-2.28-1.74-2.87-4.5h5.74c-.59 2.76-1.77 4.5-2.87 4.5zm-3.12-6c-.08-.71-.13-1.46-.13-2.25s.05-1.54.13-2.25h6.23c.08.71.13 1.46.13 2.25s-.05 1.54-.13 2.25zm-5.13-2.25c0-.78.12-1.53.32-2.25h3.3c-.08.72-.12 1.47-.12 2.25s.04 1.53.12 2.25h-3.3c-.2-.72-.32-1.47-.32-2.25zm8.25-8.25c1.1 0 2.28 1.74 2.87 4.5h-5.74c.59-2.76 1.77-4.5 2.87-4.5zm4.63 6h3.3c.2.72.32 1.47.32 2.25s-.12 1.53-.32 2.25h-3.3c.08-.72.12-1.47.12-2.25s-.04-1.53-.12-2.25zm2.71-1.5h-2.93c-.3-1.57-.79-2.91-1.42-3.93 1.89.74 3.43 2.14 4.35 3.93zm-10.33-3.93c-.63 1.02-1.12 2.36-1.42 3.93h-2.93c.92-1.79 2.46-3.19 4.35-3.93zm-4.35 11.43h2.93c.3 1.57.79 2.91 1.42 3.93-1.88-.74-3.43-2.14-4.35-3.93zm10.33 3.93c.63-1.02 1.12-2.36 1.42-3.93h2.93c-.92 1.79-2.46 3.19-4.35 3.93z"
            />

            <rect width={30.75} height={30.75} x={0.625} y={0.625} stroke={color || "#fff"} strokeWidth={1.25} rx={15.375} />
          </svg>
        </a>
      )}

      {!participantLinks?.twitter ? null : (
        <a style={{ marginRight: "2px" }} id="twitter" href={participantLinks?.twitter} target="_blank" rel="noopener noreferrer">
          <svg width={32} height={32} fill="none" viewBox="0 0 32 32">
            <g transform="translate(9,9)">
              <path
                fill={color || "#fff"}
                d="M8.33327 5.93367L13.5459 0H12.3111L7.78307 5.15107L4.1692 0H0L5.46607 7.79007L0 14.0117H1.2348L6.01347 8.5708L9.8308 14.0117H14M1.68047 0.911867H3.57747L12.3102 13.1446H10.4127"
              />
            </g>

            <rect width={30.75} height={30.75} x={0.625} y={0.625} stroke={color || "#fff"} strokeWidth={1.25} rx={15.375} />
          </svg>
        </a>
      )}

      {!participantLinks?.instagram ? null : (
        <a id="instagram" href={participantLinks?.instagram} fill="transparent" target="_blank" rel="noopener noreferrer">
          <svg width={32} height={32} fill="none" viewBox="0 0 32 32">
            <g fill={color || "#fff"} clipPath="url(#clip0)">
              <path d="M18.83 9h-5.66A4.176 4.176 0 009 13.17v5.66c0 2.3 1.871 4.17 4.17 4.17h5.66c2.3 0 4.17-1.87 4.17-4.17v-5.66C23 10.872 21.13 9 18.83 9zm2.762 9.83a2.763 2.763 0 01-2.763 2.762h-5.658a2.762 2.762 0 01-2.762-2.763v-5.658a2.761 2.761 0 012.762-2.762h5.658a2.762 2.762 0 012.763 2.762v5.658z" />
              <path d="M16 12.379a3.624 3.624 0 00-3.62 3.62c0 1.998 1.623 3.622 3.62 3.622s3.621-1.624 3.621-3.621a3.624 3.624 0 00-3.62-3.621zm0 5.833a2.212 2.212 0 110-4.425 2.213 2.213 0 010 4.425zm3.628-4.938a.867.867 0 100-1.736.867.867 0 000 1.736z" />
            </g>
            <rect width={30.75} height={30.75} x={0.625} y={0.625} stroke={color || "#fff"} strokeWidth={1.25} rx={15.375} />
            <defs>
              <clipPath id="clip0">
                <path fill={color || "#fff"} d="M0 0h14v14H0z" transform="translate(9 9)" />
              </clipPath>
            </defs>
          </svg>
        </a>
      )}

      {!participantLinks?.facebook ? null : (
        <a
          id="facebook"
          href={participantLinks?.facebook}
          fill="transparent"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginRight: "6px" }}
        >
          {" "}
          <svg width={32} height={32} fill="none" viewBox="0 0 32 32">
            <path
              fill={color || "#fff"}
              d="M17.089 25v-8.208h2.763l.418-3.2h-3.181v-2.04c0-.928.257-1.56 1.59-1.56h1.703V7.128C20.085 7.088 19.072 7 17.9 7c-2.45 0-4.129 1.488-4.129 4.232v2.36H11v3.2h2.771V25h3.318z"
            />
            <rect width={30.75} height={30.75} x={0.625} y={0.625} stroke={color || "#fff"} strokeWidth={1.25} rx={15.375} />
          </svg>
        </a>
      )}

      {!participantLinks?.linkedin ? null : (
        <a style={{ marginRight: "6px" }} id="linkedin" href={participantLinks?.linkedin} target="_blank" rel="noopener noreferrer">
          <svg width={32} height={32} fill="none" viewBox="0 0 32 32">
            <path
              fill={color || "#fff"}
              d="M12.202 22h-2.97V11.978h2.97V22zm-1.486-11.395C9.765 10.605 9 9.793 9 8.803 9 7.803 9.774 7 10.716 7c.952 0 1.717.813 1.717 1.803.009.99-.765 1.802-1.717 1.802zM23.318 22h-2.961v-4.875c0-1.159-.018-2.662-1.539-2.662-1.547 0-1.779 1.27-1.779 2.578V22h-2.97V11.978h2.846v1.373h.036c.4-.784 1.37-1.616 2.81-1.616 3.006 0 3.557 2.074 3.557 4.773V22z"
            />
            <rect width={30.75} height={30.75} x={0.625} y={0.625} stroke={color || "#fff"} strokeWidth={1.25} rx={15.375} />
          </svg>
        </a>
      )}
    </aside>
  );
};

export default MiscSocialSmallParticipant;
