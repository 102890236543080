import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import CommunityHero from "../components/CommunityHero";
import CommunityFilter from "../components/CommunityFilter";
import CommunityCardHolder from "../components/CommunityCardHolder";
import { useStaticQuery, graphql } from "gatsby";
import { ParticipantsDiv } from "../styles/participants";
import { BigButtonParticipant } from "../components/ButtonsParticipants";
import { Location } from "@reach/router";
import { AnimatedButton } from "../components/Buttons/elements";

const ParticipantsPage = () => {
  const communityQuery = useStaticQuery(graphql`
    query {
      allCraftParticipantsDefaultEntry(filter: { isDraft: { eq: false } }) {
        nodes {
          id
          communityMemberSlug
          qafParticipantType {
            ... on Craft_qafParticipantType_default_Entry {
              id
              title
            }
          }
          communityMemberWebsite
          communityMemberEmailAddress
          subtitle
          communityMemberOpeningHours
          participantLocation {
            title
          }
          communityMemberDescription
          communityMemberContactDetails {
            method
            value
          }
          slug
          title
          headerImage: qafParticipantHeaderImage {
            ... on Craft_communityAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(width: 590, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            ... on Craft_eventsAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(width: 590, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      pageSetup: craftQafPageParticipantsQafPageParticipantsEntry {
        showTitle
        pageTitle
        pageDescription
        showParticipants
        titleSeo
        descriptionSeo
        participantsFilter {
          ... on Craft_qafParticipantType_default_Entry {
            id
            title
          }
        }
      }
      allCraftQafEventsQafEventEntry {
        nodes {
          id
          title
          isStaging
          eventEndDateTime
          eventStartDateTime
          eventShortDescription
          qafEventLocation {
            title
            ... on Craft_participants_default_Entry {
              participantLocation {
                title
              }
              communityMemberOpeningHours
              communityMemberSlug
              communityMemberWebsite
              communityMemberEmailAddress
              communityMemberDescription
              communityMemberContactDetails {
                col1
                col2
              }
              qafParticipantType {
                ... on Craft_qafParticipantType_default_Entry {
                  id
                  title
                }
              }
            }
            ... on Craft_maplocation_default_Entry {
              locationId
              locationName
              title
              allowClick
            }
          }
          slug
          qafEventType {
            ... on Craft_qafEventType_default_Entry {
              id
              title
            }
          }
          headerImage: qafEventHeaderImage {
            ... on Craft_eventsAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(fit: COVER, width: 172) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [end, setEnd] = useState(9);
  const [currentTypes, setCurrentTypes] = useState([]);
  const [changedTypes, setChangedTypes] = useState(false);
  const [randomArray, setRandomArray] = useState([]);
  const [cardsFilter, setCardFilter] = useState([]);
  const [endFilter, setEndFilter] = useState(9);
  const cardsFilterTemp = useRef([]);

  const communityMembers = communityQuery?.allCraftParticipantsDefaultEntry?.nodes;

  const dataPartipantsPage = communityQuery?.pageSetup;

  const showTitle = dataPartipantsPage?.showTitle || null;
  const titlePartipantsPage = dataPartipantsPage?.pageTitle || "Participants";
  const desciptionPartipantsPage = dataPartipantsPage?.pageDescription || null;
  const showParticipants = dataPartipantsPage?.showParticipants || null;
  const titleSeo = dataPartipantsPage?.titleSeo || null;
  const descriptionSeo = dataPartipantsPage?.descriptionSeo || null;
  const filterList = dataPartipantsPage?.participantsFilter || null;

  useEffect(() => {
    cardsFilterTemp.current = communityMembers.filter((x) => (currentTypes.length === 0 ? true : checkTypes(x.qafParticipantType)));
    // console.log(currentTypes,"current cards:", currentCards);
    // console.log("cards after filter",cardsFilterTemp, "end", endFilter);
    const cardsTemp = cardsFilterTemp.current;
    setCardFilter(cardsTemp.slice(0, endFilter));
  }, [currentTypes, endFilter]);

  const currentCards = communityMembers.slice(0, end);

  const checkTypes = (typesArray) => {
    for (let i = 0; i < typesArray.length; i++) {
      if (currentTypes.indexOf(typesArray[i]?.id) > -1) {
        return true;
      }
    }
    return false;
  };

  const handleClickLoadMore = () => {
    setEnd((pre) => pre + 9);
  };

  const handleClickLoadMoreFilter = () => {
    setEndFilter((pre) => pre + 9);
  };

  return (
    <Location>
      {({ location }) => {
        return (
          <Layout
            backgroundColor={"var(--bg-color)"}
            skipHeader
            headerBlack={true}
            title={titleSeo}
            SEODescription={descriptionSeo}
            //   footerColor={"var(--red)"}
            //   title="Alserkal Avenue | A Community for
            // Contemporary Art & Homegrown Concepts"
            //   SEODescription="If you want dynamic cultural experiences, from
            // contemporary art and photography to spinning and yoga
            // classes, discover Alserkal Avenue today."
          >
            <ParticipantsDiv>
              {showTitle ? (
                <CommunityHero
                  topLine={titlePartipantsPage}
                  text={desciptionPartipantsPage}
                  colorH3={"var(--text-color)"}
                  colorH5={"var(--primary)"}
                  fontSizeH5="32px"
                />
              ) : null}

              {showParticipants && filterList?.length ? (
                <CommunityFilter
                  options={filterList}
                  selected={(e) => {
                    setCurrentTypes(e);
                    setChangedTypes(() => !changedTypes);
                  }}
                  selectLocation={location.search.indexOf("?type=") > -1 ? decodeURI(location.search.split("?type=")[1]) : ""}
                />
              ) : null}

              {showParticipants ? (
                <>
                  <CommunityCardHolder
                    cards={
                      currentTypes?.length > 0
                        ? cardsFilter
                        : currentCards.filter((x) => (currentTypes.length === 0 ? true : checkTypes(x.participantType)))
                    }
                    closeOverlay={changedTypes}
                    randomArray={randomArray}
                    cardsOrigin={communityMembers}
                  />

                  {currentTypes?.length > 0
                    ? cardsFilterTemp.current.length > endFilter &&
                      cardsFilterTemp.current.length !== endFilter && (
                        <AnimatedButton style={{margin: "0 auto 30px auto"}}>
                          <BigButtonParticipant backgroundColor="var(--btn-bg)" color="var(--btn-text) " onClick={handleClickLoadMoreFilter}>
                            Load more
                          </BigButtonParticipant>
                        </AnimatedButton>
                      )
                    : communityMembers.length > end &&
                      communityMembers.length !== end && (
                        <AnimatedButton style={{margin: "0 auto 30px auto"}}>
                          <BigButtonParticipant backgroundColor="var(--btn-bg)" color="var(--btn-text) " onClick={handleClickLoadMore}>
                            Load more
                          </BigButtonParticipant>
                        </AnimatedButton>
                      )}
                </>
              ) : null}
            </ParticipantsDiv>
          </Layout>
        );
      }}
    </Location>
  );
};

export default ParticipantsPage;
