import React from "react";
import PropTypes from "prop-types";
import { H3Strong, H5SmallMedium } from "./../Typography";
import { CommunityHeroDiv } from "./elements";
import { AnimationOnScroll } from "react-animation-on-scroll";

const CommunityHero = ({ topLine, text, colorH3, colorH5, fontSizeH5 }) => (
  <CommunityHeroDiv colorH3={colorH3} colorH5={colorH5} fontSizeH5={fontSizeH5}>
    <AnimationOnScroll
      animateIn="animate__fadeInLeft"
      animateOnce
      offset={25}
      // animateOut="animate__fadeOutLeft"
      // duration={0.75}
    >
      <H3Strong style={{ width: "100%" }}>{topLine}</H3Strong>
    </AnimationOnScroll>
    <AnimationOnScroll
      animateIn="animate__fadeInRight"
      animateOnce
      offset={50}
      // animateOut="animate__fadeOutRight"
      // duration={0.75}
    >
      <H5SmallMedium
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </AnimationOnScroll>
  </CommunityHeroDiv>
);

export default CommunityHero;

CommunityHero.propTypes = {
  topLine: PropTypes.string,
  text: PropTypes.string,
};

CommunityHero.defaultProps = {
  topLine: "Discover the Avenue",
};
