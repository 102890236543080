import styled from "styled-components";

export const ParticipantsDiv = styled.div`
  --headerHeight: 110px;
  margin-top: var(--headerHeight);
  background-color: var(--bg-color);
  /* position: relative; */
  @media (max-width: 767px) {
    padding: 35px 20px 0;
    & h5 {
      text-align: left;
      font-size: 24px;
    }
    & > div:nth-child(3) {
      width: 100%;
      margin-top: 0;
      & > div {
        & > div {
          padding: 0;
        }
      }
    }
    & > button {
      width: 100%;
      font-weight: 400;
      font-size: 18px;
    }
  }
`;

export const ParticipantHeaderDiv = styled.div`
  --headerHeight: 110px;
  padding-top: var(--headerHeight);
  background-color: var(--bg-color);
`;
